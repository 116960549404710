import { Box } from '@mui/material';
import { GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import React from 'react';
import FileUploadButton from './FileUploadButton';


const FileUploadToolbar = (props:any) => {
    
    return(
        <Box>
            <GridToolbarContainer sx={{fontWeight: 600}}>
                <FileUploadButton {...props} />
                <GridToolbarFilterButton slotProps={{
                    button: {
                        sx: {fontWeight: 600}
                    }
                }} />
            </GridToolbarContainer>
           
        </Box> 
    )
}

export default FileUploadToolbar;
