
import { useMemo, useEffect, useState } from "react";
import { useGetSovItemsQuery } from "../../dropdowns/dropdownApi";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";

const useSovMap = ({job_type}: any) => {
  const {selectedItem} = useAppSelector((state: any) => state.api);
    const [sovIDs, setSovIDs] = useState<Map<string, any>>(new Map());
    const [selectedSov, setSelectedSov] = useState<Map<string, any>>(new Map());
    const { project_id } = useParams();

    const sovParams = useMemo(() => ({
        project_id: project_id || "",
        job_type_id: selectedItem?.job_type_id?._id || job_type?._id || ""
      }), [job_type, selectedItem, project_id]);

      const { data: sovResponse } = useGetSovItemsQuery(sovParams);
    
      useEffect(() => {
        if (sovResponse?.data) {
          const m = new Map();
          const set = sovResponse.data.find((sov: any) => sov._id === selectedItem?.job_type_id?._id || sov?._id === job_type?._id);
          if (set) {
            set.items.forEach((sov: any) => {
              const key = `${sov.plan} - ${sov.elevation} - ${sov.option}${sov.option_code ? ` - ${sov.option_code}` : ""}`;
              m.set(key, sov);
            });
            setSovIDs(m);
          }
        }
      }, [sovResponse, job_type]);

      useEffect(() => {
        console.log(sovIDs)
      }, [sovIDs])

    return { sovIDs, selectedSov, setSovIDs, setSelectedSov };
}

export default useSovMap;