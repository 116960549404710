import specificationColumns from "../projects/columns/specificationColumns";

export const TagTypes = {
  COMPANIES: 'Companies',
  DIVISIONS: 'Divisions',
  JOB_TYPES: 'JobTypes',
  CUSTOMERS: 'Customers',
  SUPERVISORS: 'Supervisors',
  ESTIMATORS: 'Estimators',
  ACCOUNTANTS: 'Accountants',
  FOREMEN: 'Foremen',
  EMPLOYEES: 'Employees',
  PROJECTS: 'Projects',
  PROPOSALS: 'Proposals',
  BUDGETS: 'Budgets',
  CONTRACTS: 'Contracts',
  DATA: 'Data',
  STARTS: 'Starts',
  LOTS: 'Lots',
  FLATWORK_SF: 'FlatworkSF',
  PREBUILT_ORDERS: 'PrebuiltOrders',
  DOCUMENTS: 'Documents',
  SOV: 'SoV',
  INFORMATION: 'Information',
  CHECKLIST: 'Checklist',
  PLANDESIGN: 'PlanDesign',
  SPECIFICATIONS: 'Specifications',
  WAREHOUSEORDERS: 'WarehouseOrders',
  COST_CODES: 'CostCodes',
  COST_CODE_SEGMENT_ONE: 'CostCodeSegmentOne',
  COST_CODE_SEGMENT_TWO: 'CostCodeSegmentTwo',
  COST_TYPES: 'CostTypes',
  // Add other types as needed
} as const;

export type TagType = typeof TagTypes[keyof typeof TagTypes];

export interface TagPart {
  type: TagType;
  id?: string;
}

export const createTag = (...parts: (TagPart | undefined)[]) => {
  return parts
    .filter((part): part is TagPart => part !== undefined)
    .map(part => `${part.type}${part.id ? `:${part.id}` : ''}`)
    .join('/');
};

export const parseTag = (tag: string): TagPart[] => {
  return tag.split('/').map(part => {
    const [type, id] = part.split(':');
    return { type: type as TagType, id };
  });
};
