import { GridColDef } from "@mui/x-data-grid-premium";

export const BudgetDetailColumns:GridColDef[] = [
  {
    field: 'plan',
    headerName: 'Plan',
    headerAlign: "left",
    align: "left",
    type: 'string',
    width: 160,
    editable: false,
    filterable: true
  },
  { field: 'elevation',
     headerName: 'Elevation',
     headerAlign: "left",
     align: "left",
     type: 'string',
     width: 160,
     editable: false,
     filterable: true
  },
  { field: 'option',
     headerName: 'Option',
     headerAlign: "left",
     align: "left",
     type: 'string',
     width: 150,
     editable: false,
     filterable: true },

  { field: 'option_code',
     headerName: 'Option Code',
     headerAlign: "left",
     align: "left",
     type: 'string',
     width: 130,
     editable: false,
     filterable: true },

  { 
    field: 'total_cost',
    headerName: 'Total Cost',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 130,
    editable: false,
    filterable: true,
    valueGetter: (value:any):string => {
      return `$${value?.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
      })}`;
  }, 
  },
  
];

export const BudgetItemColumns:GridColDef[] = [
  { 
    field: 'cost_distribution',
    headerName: 'Cost Distribution',
    headerAlign: "left",
    align: "left",
    type: 'string',
    width: 150,
    editable: false,
    filterable: true },
  {
    field: 'cost_type',
    headerName: 'Cost Type',
    headerAlign: "left",
    align: "left",
    type: 'string',
    width: 120,
    editable: true,
  },
  {
    field: 'part_number',
    headerName: 'Part Number',
    headerAlign: "left",
    align: "left",
    type: 'string',
    width: 150,
    editable: false,
    filterable: true
  },
  {
    field: 'description',
    headerName: 'Description',
    headerAlign: "left",
    align: "left",
    type: 'string',
    width: 200,
    editable: false,
    filterable: true
  },
  {
    field: 'total_amount',
    headerName: 'Total Amount',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 120,
    editable: false,
    filterable: true
  },
  {
    field: 'takeoff_quantity',
    headerName: 'Takeoff Quantity',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 160,
    editable: false,
    filterable: true
  },
  { 
    field: 'takeoff_unit', 
    headerName: 'Takeoff Unit',
    headerAlign: "left",
    align: "left",
    type: 'string',
    width: 120,
    editable: false,
    filterable: true
  },
  {
    field: 'labor_quantity',
    headerName: 'Labor Quantity',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 140,
    editable: false,
    filterable: true
  },
  {
    field: 'labor_per_hour',
    headerName: 'Labor/Hour',
    headerAlign: "left",
    align: "left",
    type: 'string',
    width: 120,
    editable: false,
    filterable: true
  },
  {
    field: 'labor_cost_per_hour',
    headerName: 'Labor Cost/Hour',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 150,
    editable: false,
    filterable: true
  },
  {
    field: 'labor_per_hour2',
    headerName: 'Labor Cost/Hour 2',
    headerAlign: "left",
    align: "left",
    type: 'string',
    width: 150,
    editable: false,
    filterable: true
  },
  {
    field: 'material_conv_factor',
    headerName: 'Material Conversion Factor',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 200,
    editable: false,
    filterable: true
  },
  {
    field: 'material_conv_unit',
    headerName: 'Material Conversion Unit',
    headerAlign: "left",
    align: "left",
    type: 'string',
    width: 200,
    editable: false,
    filterable: true
  },
  {
    field: 'material_quantity',
    headerName: 'Material Quantity',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 120,
    editable: false,
    filterable: true
  },
  {
    field: 'material_price',
    headerName: 'Material Price',
    headerAlign: "left",
    align: "left",
    type: 'number',
    width: 120,
    editable: false,
    filterable: true
  },
  {
    field: 'material_unit',
    headerName: 'Material Unit',
    headerAlign: "left",
    align: "left",
    type: 'string',
    width: 130,
    editable: false,
    filterable: true
  },
  {
    field: 'material_cost_unit',
    headerName: 'Material Cost Unit',
    headerAlign: "left",
    align: "left",
    type: 'string',
    width: 160,
    editable: false,
    filterable: true
  },
  {
    field: 'sub_productivity',
    headerName: 'Sub Productivity',
    headerAlign: "left",
    align: "left",
    type: 'string',
    width: 120,
    editable: false,
    filterable: true
  },
  {
    field: 'sub_per_hour',
    headerName: 'Sub/Hour',
    headerAlign: "left",
    align: "left",
    type: 'string',
    width: 120,
    editable: false,
    filterable: true
  },
  {
    field: 'sub_cost_per_unit',
    headerName: 'Sub Cost/Unit',
    headerAlign: "left",
    align: "left",
    type: 'string',
    width: 140,
    editable: false,
    filterable: true
  },
  {
    field: 'labor_update',
    headerName: 'Labor Update',
    headerAlign: "left",
    align: "left",
    type: 'string',
    width: 140,
    editable: false,
    filterable: true
  },
  {
    field: 'rock_concrete',
    headerName: 'Rock Concrete',
    headerAlign: "left",
    align: "left",
    type: 'string',
    width: 140,
    editable: false,
    filterable: true
  },
];

export const BudgetColumns:GridColDef[] = [
  {
    field: 'job_type',
    headerName: 'Job Type',
    headerAlign: "left",
    align: "left",
    type: 'string',
    width: 160,
    editable: false,
    filterable: true,
  },
  {
    field: 'budget_rev_no',
    headerName: 'Revision',
    headerAlign: "left",
    align: "left",
    type: 'string',
    width: 80,
    editable: false,
    filterable: true,
  },
  {
    field: 'budget_name',
    headerName: 'Name',
    headerAlign: "left",
    align: "left",
    type: 'string',
    width: 160,
    editable: false,
    filterable: true,     
  },
  {
    field: 'good_through_date',
    headerName: 'Good Through Date',
    width: 120,
    type: 'date',
      headerAlign: "left",
    align: "left",
    sortable: true,
    editable: false,
    valueGetter: (value:any) => value ? new Date(value) : '',
  },
  {
    field: 'active_release',
    headerName: 'Active Release',
    headerAlign: "left",
    align: "left",
    type: 'string',
    width: 80,
    editable: false,
    filterable: true,   
  },
  {
    field: 'status',
    headerName: 'Status',
    headerAlign: "left",
    align: "left",
    type: 'string',
    width: 80,
    editable: false,
    filterable: true
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    headerAlign: "left",
    align: "left",
    type: 'date',
    width: 120,
    filterable: true,
    editable: false,
    valueGetter: (value:any) => value ? new Date(value) : '',
    valueFormatter: (value:any) => {
        if (!value) {
          return '';
        }
        const date = new Date(value);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
      },
  },
  {
      field: 'created_by',
      headerName: 'Created By',
      headerAlign: "left",
      align: "left",
      type: 'string',
      width: 150,
      filterable: true,
      editable: false,
  },
  {
      field: 'updated_at',
      headerName: 'Updated At',
      headerAlign: "left",
      align: "left",
      type: 'date',
          width: 120,
      filterable: true,
      editable: false,
      valueGetter: (value:any) => value ? new Date(value) : '',
      valueFormatter: (value:any) => {
          if (!value) {
            return '';
          }
          const date = new Date(value);
          return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
        },
  },
  {
      field: 'updated_by',
      headerName: 'Updated By',
      headerAlign: "left",
      align: "left",
      type: 'string',
      width: 120,
      filterable: true,
      editable: false,
  }
];