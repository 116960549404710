import { useMemo } from 'react';
import { GridColDef, GridRenderCellParams, GridRenderEditCellParams } from '@mui/x-data-grid-premium';
import { TextField, Grid, useTheme } from '@mui/material';

const splitFileName = (filename: string) => {
    const lastDotIndex = filename?.lastIndexOf('.');
    if (lastDotIndex === -1) return [filename, ''];
    return [filename?.slice(0, lastDotIndex), filename?.slice(lastDotIndex)];
  };

const useDocumentColumns = () => {
  const theme = useTheme(); 
  const documentColumns: GridColDef[] = useMemo(() => [
    {
      field: 'filename',
      headerName: 'Filename',
      headerAlign: 'left',
      align: 'left',
      minWidth: 120,
      flex: 2,
      editable: true,
      cellClassName: 'filename-cell',
      renderCell: (params: GridRenderCellParams) => {
        const [baseName, extension] = splitFileName(params.value as string);
        return (
          <span>
            {baseName}<span style={{ color: theme.palette.text.secondary }}>{extension}</span>
          </span>
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        const [name, extension] = params.value.split(/\.(?=[^\.]+$)/); // Split filename into name and extension

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const newName = e.target.value;
          const newValue = `${newName}.${extension}`; // Recombine name and extension
          params.api.setEditCellValue({ id: params.id, field: 'filename', value: newValue });
        };

        return (
          <Grid item xs={12}>
            <TextField
              value={name} // Only allow editing the name part
              onChange={handleChange}
              fullWidth
            />
          </Grid>
        );
      },
    },
    {
      field: 'idType',
      headerName: 'ID Type',
      headerAlign: 'left',
      align: 'left',
      minWidth: 120,
      flex: 1,
      editable: false,
      valueGetter: (value: any) => value?.metadata?.idType || '',
    },
    {
      field: 'contentType',
      headerName: 'Content Type',
      headerAlign: 'left',
      align: 'left',
      minWidth: 120,
      flex: 1,
      editable: false,
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      type: 'string',
      minWidth: 120,
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      valueGetter: (value: any) => new Date(value?.metadata?.created_at) || new Date(),
      editable: false,
    },
    {
      field: 'created_by',
      headerName: 'Created By',
      minWidth: 120,
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      valueGetter: (value: any) => value?.metadata?.created_by || '',
      editable: false,
    },
  ], []);

  return documentColumns;
};

export default useDocumentColumns;
