import { Tooltip, Box } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import React from "react";
import { formatDateStringWithTime } from "../../../utils/formatDateString";

const ProposalColumns:GridColDef[] = [
  {
    field: 'job_type',
    headerName: 'Job Type',
    headerAlign: "left",
    align: "left",
    type: 'string',
    width: 160,
    editable: false,
  },
  {
    field: 'proposal_rev_no',
    headerName: 'Revision',
    headerAlign: "left",
    align: "left",
    type: 'string',
    width: 120,
    editable: false,
  },
  {
    field: 'budget_reference',
    headerName: 'Budget Reference',
    headerAlign: "left",
    align: "left",
    type: 'string',
    width: 120,
    editable: false,
  },
  {
    field: 'proposal_name',
    headerName: 'Name',
    headerAlign: "left",
    align: "left",
    type: 'string',
    width: 180,
    editable: false,
  },
  {
    field: 'proposal_date',
    headerName: 'Date',
    width: 120,
    type: 'date',
    headerAlign: "left",
    align: "left",
    sortable: false,
    editable: false,
    // access: "admin",
    valueGetter: (value:any) => value ? new Date(value) : '',
  },
  {
    field: 'effective_date',
    headerName: 'Effective Date',
    width: 120,
    type: 'date',
    headerAlign: "left",
    align: "left",
    sortable: false,
    editable: false,
    // access: "admin",
    valueGetter: (value:any) => value ? new Date(value) : '',
  },
  {
    field: 'effective_through_date',
    headerName: 'Effective Through Date',
    width: 120,
    type: 'date',
    headerAlign: "left",
    align: "left",
    sortable: false,
    editable: false,
    // access: "admin",
    valueGetter: (value:any) => value ? new Date(value) : '',
  },
  {
    field: 'status',
    headerName: 'Status',
    type: 'string',
    width: 120,
    headerAlign: "left",
    align: "left",
    editable: false,
  },
  {
    field: 'notes',
    headerName: 'Notes',
    type: 'string',
    width: 220,
    editable: false,
    headerAlign: "left",
    align: "left",
  },
  {
    field: 'active_release',
    headerName: 'Active Release',
    type: 'string',
    width: 80,
    editable: false,
    headerAlign: "left",
    align: "left",
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    headerAlign: "left",
    align: "left",
    type: 'date',
    width: 120,
    filterable: true,
    editable: false,
    valueGetter: (value:any) => value ? new Date(value) : undefined,
    valueFormatter: (value:any) => {
      if (!value) {
        return '';
      }
      const date = new Date(value);
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    },
  },
  {
    field: 'created_by',
    headerName: 'Created By',
    headerAlign: "left",
    align: "left",
    type: 'string',
    width: 160,
    filterable: true,
    editable: false,
  },
  {
    field: 'updated_at',
    headerName: 'Updated At',
    headerAlign: "left",
    align: "left",
    type: 'date',
    width: 120,
    filterable: true,
    editable: false,
    valueGetter: (value:any) => value ? new Date(value) : undefined,
    valueFormatter: (value:any) => {
      if (!value) {
        return '';
      }
      const date = new Date(value);
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    },
  },
  {
    field: 'updated_by',
    headerName: 'Updated By',
    headerAlign: "left",
    align: "left",
    type: 'string',
    width: 160,
    filterable: true,
    editable: false,
  }
];

const ProposalDetailColumns:GridColDef[] = [
  {
    field: 'plan',
    headerName: 'Plan',
    type: 'string',
    width: 120,
    editable: false,
    headerAlign: "left",
    align: "left",
  },
  {
    field: 'elevation',
    headerName: 'Elevation',
    type: 'string',
    width: 120,
    editable: false,
    headerAlign: "left",
    align: "left",
  },
  {
    field: 'option',
    headerName: 'Option',
    type: 'string',
    width: 120,
    editable: false,
    headerAlign: "left",
    align: "left",
  },
  {
    field: 'option_code',
    headerName: 'Option Code',
    type: 'string',
    width: 160,
    editable: false,
    headerAlign: "left",
    align: "left",
  },
  {
    field: 'total_price',
    headerName: 'Total Price',
    type: 'number',
    width: 160,
    editable: false,
    headerAlign: "left",
    align: "left",
    valueGetter: (value:any):string => {
      return `$${value?.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
      })}`;
  },
  },
];

const SovColumns:GridColDef[] = [
  {
    field: 'plan',
    headerName: 'Plan',
    type: 'string',
    width: 120,
    editable: true,
    headerAlign: "left",
    align: "left",
  },
  {
    field: 'elevation',
    headerName: 'Elevation',
    type: 'string',
    width: 120,
    editable: true,
  },
  {
    field: 'option',
    headerName: 'Option',
    type: 'string',
    width: 120,
    editable: true,
  },
  {
    field: 'option_code',
    headerName: 'Option Code',
    type: 'string',
    width: 160,
    editable: true,
  }
];

const ProposalItemColumns: GridColDef[] = [
  {
    field: 'pricing_type',
    headerName: 'Pricing Type',
    type: 'string',
    width: 120,
    editable: true,
    headerAlign: "left",
    align: "left",
  },
  {
    field: 'identifying_notes',
    headerName: 'Identifying Notes',
    type: 'string',
    width: 180,
    editable: true,
    headerAlign: "left",
    align: "left",
  },
  {
    field: 'cost_type',
    headerName: 'Cost Type',
    type: 'string',
    width: 120,
    editable: true,
    headerAlign: "left",
    align: "left",
  },
  {
    field: 'cost_type_description',
    headerName: 'Cost Type Description',
    type: 'string',
    width: 220,
    editable: true,
    headerAlign: "left",
    align: "left",
  },
  {
    field: 'unit_of_measure',
    headerName: 'UoM',
    type: 'string',
    width: 80,
    editable: true,
    headerAlign: "left",
    align: "left",
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    type: 'number',
    width: 80,
    editable: true,
    headerAlign: "left",
    align: "left",
  },
  {
    field: 'proposal_price',
    headerName: 'Proposal Price',
    type: 'number',
    width: 160,
    editable: true,
    headerAlign: "left",
    align: "left",
    // Format the value for display
    valueFormatter: (value:any): string => {
      if (value == null) {
        return '';
      }
      return `$${Number(value).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
    },
  
    // Parse the input value to remove the $ sign and convert to a number
    valueParser: (value: string): number => {
      const parsedValue = parseFloat(value.replace(/[^0-9.-]+/g, ''));
      return isNaN(parsedValue) ? 0 : parsedValue;
    },
  
    // Custom rendering for the cell, including a tooltip
    renderCell: (params: GridRenderCellParams) => {
      // Format the value using the same logic as valueFormatter
      const formattedValue = params.value != null ? `$${Number(params.value).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}` : '';
  
      return (
        <Tooltip
          arrow
          title={
            <React.Fragment>
              <Box>Created At: {formatDateStringWithTime(params.row.created_at)}</Box>
              <Box>Created By: {params.row.created_by}</Box>
              <Box>Updated At: {formatDateStringWithTime(params.row.updated_at)}</Box>
              <Box>Updated By: {params.row.updated_by}</Box>
            </React.Fragment>
          }
          followCursor
        >
          <Box>{formattedValue}</Box>
        </Tooltip>
      );
    }
  },
  {
    field: 'status',
    headerName: 'Status',
    type: 'string',
    width: 120,
    editable: true,
    headerAlign: "left",
    align: "left",
  },
  
];

const ProposalItemImportColumns: GridColDef[] = [
  ...SovColumns,
  ...ProposalItemColumns
]

export default {
    ProposalColumns, 
    ProposalItemColumns,
    ProposalDetailColumns,
    SovColumns,
    ProposalItemImportColumns
}
