import React, { useCallback, useEffect, useMemo, useState } from "react";
import Grid from '@mui/material/Grid';
import {
  DataGridPro,
  GridActionsCellItem,
  GridCellParams,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
} from '@mui/x-data-grid-pro';
import { useAppDispatch } from "../../../app/hooks";
import CancelIcon from "@mui/icons-material/Close";
import styles from "./detail.module.css";
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import { apiRouteMap } from "../../routes/urls";
import { useUpdateDataMutation, useDeleteDataMutation } from "../../api/apiSliceV2";
import { showSnackbar } from "../snackbar/snackbarSlice";
import DocumentView from "../document-viewer/DocumentView";
import ConfirmationDelete from "../dialog/ConfirmationDelete";

function PanelContent(props:any) {
    const {row, items, routeSegment, columns, context, tagName} = props;
    const [rows, setRows] = useState<any>([]);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const dispatch = useAppDispatch();
    const [updateData] = useUpdateDataMutation();
    const [deleteData] = useDeleteDataMutation();
    const [open, setOpen] = useState(false);
    const [activeRow, setActiveRow] = useState<any>(null);

    useEffect(() => {
      if(items) setRows(items);
      return () => setRows([]);
    },[row, items]);

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
      if (params.reason === GridRowEditStopReasons.rowFocusOut) {
        event.defaultMuiPrevented = true;
      }
    };

    const handleConfirmDeletion = (id: GridRowId) => {
      const row = rows.find((row:any) => row._id === id);
      if(row) {
        setActiveRow(row);
        setOpen(true);
      }
    };
    
    const handleDeleteItem = useCallback(async (id: GridRowId) => {
      try {
        await deleteData({
          tagName,
          url: apiRouteMap.get(routeSegment).delete(id)
        }).unwrap();
        dispatch(showSnackbar({ message: `Item deleted: ${id}`, type: 'success' }));
        setRows((currentRows: any[]) => currentRows.filter((row:any) => row._id !== id));
      } catch (error:any) {
        dispatch(showSnackbar({ message: error?.message, type: 'error' }));
      }
    },[deleteData, dispatch, tagName, routeSegment]);
         
    const handleCancelClick = (id: GridRowId) => () => {
      setRowModesModel({
          ...rowModesModel,
          [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
  
      const editedRow = rows.find((row:any) => row._id === id);
      if (editedRow && editedRow!.isNew) {
          setRows(rows.filter((row:any) => row._id !== id));
      }
    };
    
    const processRowUpdate = useCallback(async (newRow: any) => {
      try {
        const response = await updateData({
          tagName,
          body: newRow,
          url: apiRouteMap.get(routeSegment).update(newRow._id)
        }).unwrap();
        dispatch(showSnackbar({ message: response?.message, type: 'success' }));

        setRows((rows:any) => rows.map((row:any) => (row._id === newRow._id) ? newRow : row));
        return newRow;
      } catch (error:any) {
        dispatch(showSnackbar({ message: error?.data?.message || error?.data?.message, type: 'error' }));
      }
    }, []);
  
    const handleRowModesModelChange = (rowModesModel: GridRowModesModel) => {
      setRowModesModel(rowModesModel);
    }

    const editColumn = useMemo(() => {
      return {
        field: 'actions',
        type: 'actions',
        headerName: '',
        width: 80,
        cellClassName: 'actions',
        getActions: (props:any) => {
      const {id} = props;
      if(rowModesModel[id] && rowModesModel[id].mode === GridRowModes.Edit){
        return [
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            className="textPrimary"
            onClick={handleCancelClick(id)}
            color="primary"
          />
        ];   
      }
      return [
        <GridActionsCellItem
          icon={<DeleteForeverSharpIcon />}
          label="Edit"
          className="textPrimary"
          onClick={() => handleConfirmDeletion(id)}
          color="primary"
        />  
          ];
        },
      };
    }, [rowModesModel, handleCancelClick, handleDeleteItem]);

    const getProps = useCallback(() => {
      let props = {};
      switch(routeSegment){
        case '/warehouse/orders/starts':
          props = {
            toolbar: {
              ...context?.panel?.toolbarProps?.toolbar, // Merge existing toolbar props
              row: row,
              order_id: row?._id,
              tagName
            }
          };
          break;
        case '/starts/items/lots':
          props = {
            footer: {
              ...context?.panel?.footerProps?.footer,
              row,
              context,
              tagName
            }
          };
          break;
        default:
          break;
      }
      return props;
    }, [row, routeSegment, context, tagName]);

    return (
      <Grid container justifyContent="center" alignItems="center" className={styles.detailPanel} spacing={2}>
        {context?.panel?.hasDocuments &&
        <Grid item sm={12} xs={12} >
          <DocumentView _id={row?._id} />
        </Grid>}
        <Grid item sm={12} xs={12} sx={{position: "sticky"}}>
          <DataGridPro
            sx={{
              fontSize: 14, 
              fontWeight: 500, 
              border: "1px solid rgba(0,0,0,1)",
              padding: 2,
              height: "auto",
              maxHeight: "100%",
              '& .MuiDataGrid-row:hover': {
                opacity: "0.5"
              },
              '& .MuiDataGrid-columnHeader': {
                whiteSpace: 'normal',
                lineHeight: '1.5',
                wordWrap: 'break-word',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                overflow: 'visible',
                whiteSpace: 'normal',
                lineHeight: '1.5',
                wordWrap: 'break-word',
              },
            }}
            key={row}
            autosizeOnMount={true}
            className={styles.gridStyle}
            columns={editColumn ? [editColumn, ...(columns || [])] : (columns || [])}
            rows={rows || []}
            editMode="row"
            getRowId={row => row._id || row?.id || row?.order_id}
            density="compact"
            rowHeight={52 }
            rowBufferPx={10}
            rowReordering
            getCellClassName={(params: GridCellParams<any, any, number>) => {
              return params.isEditable ? `${styles.editableChild}` : '';
            }}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            slots={{
              ...(context?.panel?.toolbar && { toolbar: context?.panel?.toolbar }),  
              ...(context?.panel?.footer && { footer: context?.panel?.footer }),  
            }}
            slotProps={getProps()} 
          />
        </Grid>
        <ConfirmationDelete row={activeRow} open={open} setOpen={setOpen} handleDelete={handleDeleteItem} />
      </Grid>
    );
}

export default PanelContent;


  