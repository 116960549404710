import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../app/hooks";
import { getOrdProcEnt as getOrdProcEntAction } from "../reportSlice";
import { useCallback } from "react";

interface UseOrdProcEntProps {
    startId: string;
    orderId?: string;
}

const useOrdProcEnt = ( ) => {
    const dispatch = useAppDispatch();
    const {start_id} = useParams(); 
    const fetchOrdProcEnt = useCallback((orderId: string) => {
        dispatch(getOrdProcEntAction({
            fileName: `${start_id}-${orderId}-OrdProcEnt`.toUpperCase(),
            url: `start_id=${start_id}&order_id=${orderId}`
        }));
    }, [dispatch, start_id]);

    return { fetchOrdProcEnt };
};

export default useOrdProcEnt;