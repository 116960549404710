import React, { useEffect, useState } from 'react';
import { MenuItem, Autocomplete, Grid, TextField } from '@mui/material';
import { useAppSelector } from '../../../app/hooks';
import ImportComponent from './Import';
import ClipboardForm from './Clipboard';
import Form from './Form';
import Revisions from '../../proposals/actions/Revisions';
import CreateOrder from '../../prebuilt-orders/actions/CreateOrder';
import useSovMap from '../hooks/useSovMap';
import withSovComponent from './withSovComponent';
import useJobTypes from '../hooks/useJobTypes';

interface SelectFormProps {
  step: any;
}
 
export const requiredFields:any = {
    specifications: ["grouping_type", "specification", "specification_value"],
    'prebuilt-order-items': ["plan"],
    'project-information': ["item"],
    'flatwork-sfs': ["plan", "elevation", "option"],
    'fnd-concrete-pricings': ["job_code", "builder", "project"],
    'fnd-aggregate-pricings': ["job_code", "builder", "project"],
    'warehouse-materials': ["part_number", "description"],
    'project-checklist': ["checklist_item", "segment_one", "segment_one_description"],
    "locations": ["taxRate", "mapLink", "geolocation"],
    "lots": ["lot_number"],
    "job-types": ["job_type_number"],
    "plan-designs": ["design_type"],
    proposals: ["plan", "elevation", "option"],
    contracts: ["plan", "elevation", "option"],
    'companies': ["company_number"],
    'divisions': ["division_number"],

  }
  
  export const jobTypeRequired:any = {
    "specifications": true,
    'project-information': false,
    'flatwork-sfs': true,
    'prebuilt-orders': true,
  }
 
const SelectForm: React.FC<SelectFormProps> = React.memo((props) => {
  const { step } = props;
  const { selectedItem } = useAppSelector((state: any) => state.api);
  const [selectedJobType, setSelectedJobType] = useState<string>("");
  const [jobType, setJobType] = useState<any>(null);
  const [autocompleteOptions, setAutocompleteOptions] = useState<{ job_type: string[] }>({ job_type: [] });
  const [selectedOption, setSelectedOption] = useState<string>(step?.importUrl ? "import" : "clipboard");
  const WithSovForm = withSovComponent(Form, {job_type: jobType});
  const { jobTypes } = useJobTypes({route: step?.route});
  const { sovIDs, selectedSov, setSovIDs, setSelectedSov } = useSovMap({job_type: jobType});

  console.log(sovIDs)

  useEffect(() => {
    if (jobTypes) {
      setAutocompleteOptions({
        job_type: jobTypes.map((j: any) => j.job_type_description)
      });
    }
  }, [jobTypes]);

  useEffect(() => {
    const jobType = jobTypes?.find((jobType: any) => jobType.job_type_description === selectedJobType)
    if(jobType)
        setJobType(jobType)
  },[jobTypes, selectedJobType]);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  const handleSovChange = (event: any, id: string) => {
    const m = new Map();
    m.set(id, event.target.value);
    setSelectedSov(m);
  };

  const getJobTypeComponent = () => {
    if (step?.options?.job_type) {
      return (
        <Grid item>
            <Autocomplete
              freeSolo
              sx={{ width: 220 }}
              options={autocompleteOptions.job_type}
              getOptionLabel={(option: string) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Job Type"
                  variant="outlined"
                  size="small"
                />
              )}
              value={selectedJobType}
              onChange={(event, newValue) => {
                setSelectedJobType(newValue || "");
              }}
            />
          </Grid>
      )
    }
    return null;
  }

  const getModeSelect = () => {
      return (
        <Grid item>
          <TextField
            select
            label="Select Mode"
            value={selectedOption}
            onChange={handleOptionChange}
            size='small'
            sx={{ minWidth: 120 }}
            variant="outlined"
          >
            {step?.importUrl && <MenuItem value="import">Import</MenuItem>}
            {!step?.options?.sov && <MenuItem value="form">Form</MenuItem>}
            <MenuItem value={step?.route === "/prebuilt-orders" ? "default list" : "clipboard"}>{
              step?.route === "/prebuilt-orders" ? "Default List" : "Clipboard"
            }</MenuItem>
          </TextField>
        </Grid>
      );
  }

  return (
    <Grid item xs={12}>
      <Grid container>
        {selectedOption === "import" && (
          <ImportComponent
            {...props}
            getJobTypeComponent={getJobTypeComponent}
            getModeSelect={getModeSelect}
            jobType={jobType}
          />
        )}
        {selectedOption === "form" && (
          !step?.options?.sov && (
            <Form
              {...props}
              getJobTypeComponent={getJobTypeComponent}
              getModeSelect={getModeSelect}
              jobType={jobType}
              sovIDs={sovIDs}
              selectedSov={selectedSov}
              handleSovChange={handleSovChange}
            />
          )
        )}
        {selectedOption === "clipboard" && (
          <ClipboardForm  
              {...props}
              getJobTypeComponent={getJobTypeComponent}
              getModeSelect={getModeSelect}
              jobType={jobType}
              sovIDs={sovIDs}
              selectedSov={selectedSov}
              setSelectedSov={setSelectedSov} 
          />        
        )}
        {step?.route === "/prebuilt-orders" && 
        selectedOption === "default list" && (
          <CreateOrder
            {...props}
            jobType={jobType}
            jobTypes={jobTypes}
            getJobTypeComponent={getJobTypeComponent}
            getModeSelect={getModeSelect}
          />
        )}
      </Grid>
    </Grid>
  );
});

export default SelectForm;

