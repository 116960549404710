import { Tooltip, Box } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import React from "react";
import { formatDateString, formatDateStringWithTime } from "../../../utils/formatDateString";

export const PlpColumns:GridColDef[] = [
  {
    field: 'job_type_id',
    headerName: 'Job Type',
    headerAlign: "left",
    align: "left",
    type: 'string',
    sortable: true,
    width: 220,
    editable: false,
    valueGetter: (value:any, row:any) => {
      return row?.job_type_id?.[0]?.job_type_description ||  ''
    },
    valueFormatter: (value:any) => {
      return value
    },
    groupable: true,
    groupingValueGetter: (value:any, row:any) => row?.job_type_id?.[0]?.job_type_description || '',

  },
  {
    field: 'plan',
    headerName: 'Plan',
    headerAlign: "left",
    align: "left",
    type: 'string',
    sortable: true,
    width: 120,
    editable: false,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip
        arrow
        title={
          //format(parseISO(params.value), 'PPPp')
          <React.Fragment>
            <Box>Created At: {formatDateString(params.row.created_at)}</Box>
            <Box>Created By: {params.row.created_by}</Box>
            <Box>Updated At: {formatDateString(params.row.updated_at)}</Box>
            <Box>Updated By: {params.row.updated_by}</Box>
          </React.Fragment>
        }
        followCursor
      >
        <Box>{params.value}</Box>
      </Tooltip>
    ),
  },
  {
    field: 'elevation',
    headerName: 'Elevation',
    headerAlign: "left",
    align: "left",
    type: 'string',
    sortable: true,
    width: 120,
    editable: false,
  },
  {
    field: 'option',
    headerName: 'Option',
    headerAlign: "left",
    align: "left",
    type: 'string',
    sortable: true,
    width: 120,
    editable: false,
  },
  {
    field: 'option_code',
    headerName: 'Option Code',
    headerAlign: "left",
    align: "left",
    type: 'string',
    sortable: true,
    width: 120,
    editable: false,
  },
  {
    field: 'number_of_orders',
    headerName: 'Number of Orders',
    headerAlign: "left",
    align: "left",
    type: 'number',
    sortable: true,
    width: 120,
    editable: false,
  },
];

export const plpItemColumns:GridColDef[] = [
  {
    field: 'part_number',
    headerName: 'Part Number',
    headerAlign: "left",
    align: "left",
    type: 'string',
    sortable: true,
    width: 160,
    editable: false,
  },
  {
    field: 'part_description',
    headerName: 'Part Description',
    headerAlign: "left",
    align: "left",
    type: 'string',
    sortable: true,
    width: 300,
    editable: false,
  },
  {
    field: 'unit_of_measure',
    headerName: 'UoM',
    headerAlign: "left",
    align: "left",
    type: 'string',
    sortable: true,
    width: 80,
    editable: false,
  },
  {
    field: 'prebuilt_quantity',
    headerName: 'Quantity',
    headerAlign: "left",
    align: "left",
    type: 'number',
    sortable: true,
    width: 80,
    editable: true,
  },
  {
    field: 'segment_one',
    headerName: 'Seg One',
    headerAlign: "left",
    align: "left",
    type: 'string',
    sortable: true,
    width: 80,
    editable: false,
  },
  {
    field: 'segment_two',
    headerName: 'Seg Two',
    headerAlign: "left",
    align: "left",
    type: 'string',
    sortable: true,
    width: 80,
    editable: false,
  },
];

export const orderColumns:GridColDef[] = [  
  {
    field: 'order_number',
    headerName: 'Order #',
    headerAlign: "left",
    align: "left",
    type: 'number',
    sortable: true,
    width: 120,
    editable: true,
  },
  {
    field: 'status',
    headerName: 'Status',
    headerAlign: "left",
    align: "left",
    type: 'string',
    sortable: true,
    width: 120,
    editable: true,
  },
  { 
    field: 'created_at', 
    headerName: 'Created At', 
    headerAlign: "left",
    align: "left",
    type: 'date', 
    width: 220, 
    valueGetter: (value:any) => formatDateStringWithTime(value),
    editable: false, 
    filterable: true 
  },
  { 
    field: 'created_by', 
    headerName: 'Created By', 
    headerAlign: "left",
    align: "left",
    type: 'string', 
    width: 220, 
    editable: false, 
    filterable: true 
  },
];

export default PlpColumns;