import { Link, Select } from "@mui/material";
import { MenuItem } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";

const StartColumns: GridColDef[] = [
    {
        field: 'job_number',
        headerName: 'Job Number',
        width: 120,
        type: 'string',
        headerAlign: "left",
        align: "left",
        sortable: true,
        editable: false,
        valueGetter: (value:any, row:any) => {
            return row?.job_number || '';
        },
    
    },
    {
        field: 'subjob_number',
        headerName: 'Subjob Number',
        width: 120,
        type: 'string',
        headerAlign: "left",
        align: "left",
        sortable: true,
        editable: false,
        renderCell: (params:any) => {
            return <Link href={`/projects/${params.row.project_id}/starts/${params.row._id}`}>{params.row.subjob_number}</Link>
        },
    },
    {
        field: 'project',
        headerName: 'Project',
        width: 220,
        type: 'string',
        headerAlign: "left",
        align: "left",
        sortable: true,
        editable: false,
    },
    { 
        field: 'job_type_id', 
        headerName: 'Job Type',
        headerAlign: "left",
        align: "left",
        width: 160, 
        type: 'string' ,
        editable: false,
        filterable: true,
        groupable: true,
        valueGetter: (value:any, row:any) => {
            return row?.job_type_id?.job_type_description || '';
        },
        groupingValueGetter: (value:any, row:any) => {
            return row?.job_type_id?.job_type_description || '';
        },
        
      },   
    {
        field: 'supervisor',
        headerName: 'Supervisor',
        width: 220,
        type: 'string',
        headerAlign: "left",
        align: "left",
        sortable: true,
        editable: false,
        valueGetter: (value:any, row:any) => {
            return row?.supervisor?.employee_name || '';
        },
        groupable: true,  
        valueFormatter: (value: any, row: any) => {
            return row?.supervisor?.employee_name || '';
        },
        groupingValueGetter(value: any, row: any) {
            return row?.supervisor?.employee_name || '';
        },
    },
    {
        field: 'lots',
        headerName: 'Lots',
        width: 160,
        type: 'string',
        headerAlign: "left",
        align: "left",
        sortable: true,
        editable: false,
        valueGetter: (value: any, row: any) => {
            if (Array.isArray(row?.lots)) {
                const lotNumbers = row?.lots?.map((lot: any) => lot?.lot_number)?.join(', ');
                return lotNumbers;
            }
            return '';
        },
        groupable: true,
        valueFormatter: (value: any, row: any) => {
            if (Array.isArray(row?.lots)) {
                return row?.lots?.map((lot: any) => lot?.lot_number)?.join(', ') || '';
            }
            return '';
        },
        groupingValueGetter(value: any, row: any) {
            if (Array.isArray(row?.lots)) {
                return row?.lots?.map((lot: any) => lot?.lot_number)?.join(', ') || '';
            }
            return '';
        },
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 120,
        type: 'string',
        headerAlign: "left",
        align: "left",
        sortable: true,
        editable: false,
        renderCell: (value:any) => (
            <span style={{ color: String(value?.value).toUpperCase() === 'ACTIVE' ? 'green' : value.value === 'INACTIVE' ? 'red' : '#0074D9' }}>
                {`${value.value}`.toUpperCase()}
            </span>
        ),
    },
    {
        field: 'start_date',
        headerName: 'Start Date',
        width: 120,
        type: 'date',
        headerAlign: "left",
        align: "left",
        sortable: true,
        editable: false,
        valueGetter: (value:any) => value ? new Date(value) : null,
    },
    {
        field: 'notes',
        headerName: 'Notes',
        width: 220,
        type: 'string',  // Keep as string for display purposes
        headerAlign: "left",
        align: "left",
        sortable: false,
        editable: false,
        valueGetter: (value: any, row: any) => {
            if (Array.isArray(row?.notes)) {
                return row?.notes.join('\n');
            } else {
                // If notes is null, undefined, or not an array, return an empty string
                return '';
            }
        },
        valueSetter: (value: any, row: any) => {
            // When editing, split the string back into an array
            const updatedNotes = value.value.split('\n').filter((note: string) => note.trim() !== '');
            return { ...row, notes: updatedNotes };
        },
        groupable: false
    },
    {
        field: 'created_by',
        headerName: 'Created By',
        width: 180,
        type: 'string',
        headerAlign: "left",
        align: "left",
        sortable: true,
        editable: false,
    },
    {
        field: 'created_at',
        headerName: 'Created At',
        width: 180,
        type: 'date',
        headerAlign: "left",
        align: "left",
        sortable: true,
        editable: false,
        valueGetter: (value:any) => value ? new Date(value) : null,
        valueFormatter: (value: any) => {
            if (!value) {
              return '';
            }
            const date = new Date(value);
            return `${date?.toLocaleDateString()} ${date?.toLocaleTimeString()}`;
        },
    },  
    {
        field: 'updated_by',
        headerName: 'Updated By',
        width: 180,
        type: 'string',
        headerAlign: "left",
        align: "left",
        sortable: true,
        editable: false,
    },
    {
        field: 'updated_at',
        headerName: 'Updated At',
        width: 180,
        type: 'date',
        headerAlign: "left",
        align: "left",
        sortable: true,
        editable: false,
        valueGetter: (value:any) => value ? new Date(value) : null,
        valueFormatter: (value: any) => {
            if (!value) {
              return '';
            }
            const date = new Date(value);
            return `${date?.toLocaleDateString()} ${date?.toLocaleTimeString()}`;
        },
    },
];

const StartStatusColumns: GridColDef[] = [
    {
        field: 'job_number',
        headerName: 'Job Number',
        width: 120,
        type: 'string',
        headerAlign: "left",
        align: "left",
        sortable: true,
        editable: false,
    },
    {
        field: 'subjob_number',
        headerName: 'Subjob Number',
        width: 120,
        type: 'string',
        headerAlign: "left",
        align: "left",
        sortable: true,
        editable: false,
    },
    {
        field: 'project_name',
        headerName: 'Project',
        width: 220,
        type: 'string',
        headerAlign: "left",
        align: "left",
        sortable: true,
        editable: false,
    },
    {
        field: 'builder_name',
        headerName: 'Builder',
        headerAlign: "left",
        align: "left",
        flex: 1,
        minWidth: 180,
    },
    {
        field: 'job_type',
        headerName: 'Job Type',
        headerAlign: "left",
        align: "left",
        flex: 1,
        minWidth: 220,
    },
    {
        field: 'lots',
        headerName: 'Lots',
        width: 160,
        type: 'string',
        headerAlign: "left",
        align: "left",
        sortable: true,
        editable: false,
        valueGetter: (value:any, row:any) => {
            if (Array.isArray(row?.lots)) {
                return row?.lots?.map((lot:any) => lot?.lot_number)?.join(', ') || '';
            }
            return '';
        },
    },
    // JC1 Group
    {
        field: 'jc1_status',
        headerName: 'JC1 Status',
        headerAlign: "left",
        align: "left",
        flex: 1,
        minWidth: 220,
        editable: true,
        renderEditCell: (params) => {
            return (
                <Select
                    value={params.value}
                    onChange={(e:any) => params.api.setEditCellValue({ 
                        id: params.id, 
                        field: params.field, 
                        value: e.target.value 
                    }, e)}
                    fullWidth
                    sx={{
                        height: '100%',
                        '& .MuiSelect-select': {
                            paddingY: '0',
                            display: 'flex',
                            alignItems: 'center'
                        }
                    }}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                width: params.colDef.computedWidth, // Match column width
                                maxHeight: 300
                            }
                        }
                    }}
                >
                    <MenuItem value="ASSURANCE REQUESTED">ASSURANCE REQUESTED</MenuItem>
                    <MenuItem value="ASSURANCE COMPLETED">ASSURANCE COMPLETED</MenuItem>
                </Select>
            );
        },
    },
    {
        field: 'jc1_date',
        headerName: 'JC1 Date',
        headerAlign: "left",
        align: "left",
        flex: 1,
        minWidth: 120,
        type: 'date',
        editable: false,
        valueFormatter: (value: any) => 
            value ? new Date(value).toLocaleDateString() : '',
    },
    {
        field: 'jc1_by',
        headerName: 'JC1 By',
        headerAlign: "left",
        align: "left",
        flex: 1,
        minWidth: 120,
        editable: false,
    },
    {
        field: 'jc1_notes',
        headerName: 'JC1 Notes',
        headerAlign: "left",
        align: "left",
        flex: 2,
        minWidth: 200,
        editable: true,
    },

    // AS1 Group
    {
        field: 'as1_status',
        headerName: 'AS1 Status',
        headerAlign: "left",
        align: "left",
        flex: 1,
        minWidth: 180,
        editable: true,
        renderEditCell: (params) => {
            return (
              <Select fullWidth value={params.value} onChange={(e:any) => params.api.setEditCellValue({ id: params.id, field: params.field, value: e.target.value }, e)}>
                <MenuItem value="PRICING UNDER REVIEW">PRICING UNDER REVIEW</MenuItem>
                <MenuItem value="PRICING DOES NOT MATCH">PRICING DOES NOT MATCH</MenuItem>
                <MenuItem value="ESTIMATING REVIEW">ESTIMATING REVIEW</MenuItem>
                <MenuItem value="COMPLETED">COMPLETED</MenuItem>
              </Select>
            )
          
        },
    },
    {
        field: 'as1_date',
        headerName: 'AS1 Date',
        headerAlign: "left",
        align: "left",
        flex: 1,
        minWidth: 120,
        type: 'date',
        editable: false,
        valueFormatter: (value: any) => 
            value ? new Date(value).toLocaleDateString() : '',
    },
    {
        field: 'as1_by',
        headerName: 'AS1 By',
        headerAlign: "left",
        align: "left",
        flex: 1,
        minWidth: 120,
        editable: false,
    },
    {
        field: 'as1_notes',
        headerName: 'AS1 Notes',
        headerAlign: "left",
        align: "left",
        flex: 2,
        minWidth: 200,
        editable: true,
    },

    // JC2 Group
    {
        field: 'jc2_status',
        headerName: 'JC2 Status',
        headerAlign: "left",
        align: "left",
        flex: 1,
        minWidth: 180,
        editable: true,
        renderEditCell: (params) => {
            return (
              <Select fullWidth value={params.value} onChange={(e:any) => params.api.setEditCellValue({ id: params.id, field: params.field, value: e.target.value }, e)}>
                <MenuItem value="ESTIMATING REVIEW">ESTIMATING REVIEW</MenuItem>
                <MenuItem value="ESTIMATING REVIEW COMPLETED">ESTIMATING REVIEW COMPLETED</MenuItem>
                <MenuItem value="COMPLETED">COMPLETED</MenuItem>
              </Select>
            )
          
        },
    },
    {
        field: 'jc2_date',
        headerName: 'JC2 Date',
        headerAlign: "left",
        align: "left",
        flex: 1,
        minWidth: 120,
        type: 'date',
        editable: false,
        valueFormatter: (value: any) => 
            value ? new Date(value).toLocaleDateString() : '',
    },
    {
        field: 'jc2_by',
        headerName: 'JC2 By',
        headerAlign: "left",
        align: "left",
        flex: 1,
        minWidth: 120,
        editable: false,
    },
    {
        field: 'jc2_notes',
        headerName: 'JC2 Notes',
        headerAlign: "left",
        align: "left",
        flex: 2,
        minWidth: 200,
        editable: true,
    },

    // AS2 Group
    {
        field: 'as2_status',
        headerName: 'AS2 Status',
        headerAlign: "left",
        align: "left",
        flex: 1,
        minWidth: 180   ,
        editable: true,
        renderEditCell: (params) => {
            return (
                <Select
                    value={params.value}
                    onChange={(e:any) => params.api.setEditCellValue({ 
                        id: params.id, 
                        field: params.field, 
                        value: e.target.value 
                    }, e)}
                    fullWidth
                    sx={{
                        height: '100%',
                        '& .MuiSelect-select': {
                            paddingY: '0',
                            display: 'flex',
                            alignItems: 'center'
                        }
                    }}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                width: params.colDef.computedWidth, // Match column width
                                maxHeight: 300
                            }
                        }
                    }}
                >
                    <MenuItem value="PRICING UNDER REVIEW">PRICING UNDER REVIEW</MenuItem>
                    <MenuItem value="PO's UNAVAILABLE">PO's UNAVAILABLE</MenuItem>
                    <MenuItem value="PRICING DOES NOT MATCH">PRICING DOES NOT MATCH</MenuItem>
                    <MenuItem value="START ITEM UPDATE REQUESTED">START ITEM UPDATE REQUESTED</MenuItem>
                    <MenuItem value="ESTIMATING REVIEW">ESTIMATING REVIEW</MenuItem>
                    <MenuItem value="ESTIMATING REVIEW COMPLETED">ESTIMATING REVIEW COMPLETED</MenuItem>
                    <MenuItem value="COMPLETED">COMPLETED</MenuItem>
                </Select>
            )
        },
    },
    {
        field: 'as2_date',
        headerName: 'AS2 Date',
        headerAlign: "left",
        align: "left",
        flex: 1,
        minWidth: 120,
        type: 'date',
        editable: false,
        valueFormatter: (value: any) => 
            value ? new Date(value).toLocaleDateString() : '',
    },
    {
        field: 'as2_by',
        headerName: 'AS2 By',
        headerAlign: "left",
        align: "left",
        flex: 1,
        minWidth: 120,
        editable: false,
    },
    {
        field: 'as2_notes',
        headerName: 'AS2 Notes',
        headerAlign: "left",
        align: "left",
        flex: 2,
        minWidth: 200,
        editable: true,
    },

    // Metadata
    {
        field: 'created_at',
        headerName: 'Created At',
        headerAlign: "left",
        align: "left",
        flex: 1,
        minWidth: 120,
        type: 'date',
        editable: false,
        valueFormatter: (value: any) => 
            value ? new Date(value).toLocaleDateString() : '',
    },
    {
        field: 'created_by',
        headerName: 'Created By',
        headerAlign: "left",
        align: "left",
        flex: 1,
        minWidth: 120,
        editable: false,
    },
    {
        field: 'updated_at',
        headerName: 'Updated At',
        headerAlign: "left",
        align: "left",
        flex: 1,
        minWidth: 120,
        type: 'date',
        editable: false,
        valueFormatter: (value: any) => 
            value ? new Date(value).toLocaleDateString() : '',
    },
    {
        field: 'updated_by',
        headerName: 'Updated By',
        headerAlign: "left",
        align: "left",
        flex: 1,
        minWidth: 120,
        editable: false,
    },
];

const StartCols = {StartStatusColumns, StartColumns};
export default StartCols;
