import { GridColDef } from "@mui/x-data-grid-premium";

export const whMaterialPricingColumns: GridColDef[] = [
  {
    field: 'part_number',
    headerName: 'Part Number',
    sortable: true,
    type: 'string',
    width: 120,
    editable: true,
  },
  {
    field: 'part_description',
    headerName: 'Description',
    width: 260,
    type: 'string',
    sortable: true,
    editable: true
  },
  {
    field: 'cost_code',
    headerName: 'Cost Code',
    type: 'string',
    sortable: true,
    width: 120,
    editable: true,
  },
  {
    field: 'cost_type',
    headerName: 'Cost Type',
    type: 'string',
    sortable: true,
    width: 80,
    editable: true,
  },
  {
    field: 'unit_of_measure',
    headerName: 'UoM',
    type: 'string',
    width: 80,
    editable: true,
  },
  {
    field: 'default',
    type: 'boolean',
    width: 80,
    editable: false,
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    headerAlign: "left",
    align: "left",
    type: 'date',
    width: 120,
  filterable: true,
  editable: false,
  valueGetter: (value:any) => value ? new Date(value) : undefined,
  valueFormatter: (value:any) => {
    if (!value) {
      return '';
    }
    const date = new Date(value);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  },
},
{
  field: 'created_by',
  headerName: 'Created By',
  headerAlign: "left",
  align: "left",
  type: 'string',
  flex: 1,
  filterable: true,
  editable: false,
},
{
  field: 'updated_at',
  headerName: 'Updated At',
  headerAlign: "left",
  align: "left",
  type: 'date',
  width: 120,
  filterable: true,
  editable: false,
  valueGetter: (value:any) => value ? new Date(value) : undefined,
  valueFormatter: (value:any) => {
    if (!value) {
      return '';
    }
    const date = new Date(value);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  },
},
{
  field: 'updated_by',
  headerName: 'Updated By',
  headerAlign: "left",
  align: "left",
  type: 'string',
  flex: 1,
  filterable: true,
  editable: false,
}
];