import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { TagTypes } from "../api/tagTypes";
import ActionToolbar from "../components/grid/toolbars/ActionToolbar";
import CustomGridToolbar from "../components/grid/toolbars/CustomGridToolbar";
import { DetailConfig } from "../components/interfaces/DetailConfig";
import StartCols from "./columns/startColumns";
import startsSteps from "./actions/steps";
import { useAppSelector } from "../../app/hooks";


export const useStartConfig = (): { tabs: DetailConfig[] } => {
  const {company, division} = useAppSelector((state:any) => state.auth);  

  const tabs = useMemo<DetailConfig[]>(() => [
    {
      index: 0,
      route: '/starts',
      tagType: TagTypes.STARTS,
      params: { company_id: company._id, division_id: division._id },
      label: 'Start Tracker',
      steps: startsSteps?.requestStart,
      redirectUrl: (...args:any) => {
        const [project_id, start_id] = args;
        return `/projects/${project_id}/starts/${start_id}`
      },
      header: false,
      detail: {
        columns: StartCols?.StartColumns,
        toolbar: CustomGridToolbar,
        toolbarProps: {},
        footer: undefined,
        footerProps: {},
        actions: {
          expandable: false,
          exportable: false,
          deletable: false,
          editable: true,
        },
        hasDocuments: false,
      },
      panel: undefined
    },
    {
      index: 1,
      route: '/start-statuses',
      params: { company_id: company._id, division_id: division._id },
      tagType: TagTypes.STARTS,
      label: 'Start Status',
      header: false,
      detail: {
        columns: StartCols?.StartStatusColumns,
        toolbar: CustomGridToolbar,
        toolbarProps: {
          options: {
            showHandleDeleteAll: false,
          }
        },
        footer: undefined,
        footerProps: {},
        actions: {
          expandable: false,
          exportable: false,
          deletable: true,
          editable: true,
        },
        hasDocuments: false,
      },
      panel: undefined,
    },
  ], [company, division]);

  return { tabs }; // Return the tabs object within an object
};

export default useStartConfig;
